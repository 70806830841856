import React, { useEffect, useState } from "react";
import Routing from "./Routing";
// import { CToast, Header, Footer, UserUtils, Services, LoginView, useToast } from "fe_comp_base";
import CToast from "../basecomp/components/CToast";
import { useToast } from "../basecomp/components/provider/ToastProvider";
import Header from "../basecomp/main/Header";
import Footer from "../basecomp/main/Footer";
import Services from "../basecomp/main/Services";
import UserUtils from "../basecomp/auth/UserUtils";
import LoginView from "../basecomp/auth/view/LoginView";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ConasLogo from "./assets/conas_logo.png";
import ConasLogoSmall from "./assets/logo512.png";
import BgImg from "./assets/anybg.jpg";
import warehouseimg from "../home/assets/warehouse.jpeg";
import dcatimg from "../home/assets/d-catalogue.jpeg";
import fuelimg from "../home/assets/f.jpeg";
import glnimg from "../home/assets/gln.jpg";

import LabelPrinting from "../label_printing/src/view/AddPrintingDetails";
import UploadLabel from "../label_printing/src/view/UploadLabel";
import PrintingView from "../label_print/src/view/PrintingView";
import BinLocationView from "../label_print/src/view/BinLocationView";
import UploadLabelView from "../label_print/src/view/UploadLabelView";
import ImageUploadView from "../image_upload/src/view/ImageUploadView";
import RegistrationDataView from "../gsone/src/view/RegistrationView";
import SparePartInfoView from "../gsone/src/view/SparePartInfoView";
import FuelAssetInfoView from "../gsone/src/view/FuelAssetView";
import HistoricalJob from "../d_catalogue/src/hist_jods/view/HistoricalJob";
import ShoppingBasket from "../d_catalogue/src/sp_basket/view/ShoppingBasket";
import HistoricalJobDetails from "../d_catalogue/src/hist_jods/view/HistoricalJobDetails";

import ERPImplementationPage from "../home/src/ERPImplementation";
import HomePage from "../home/src//Home";
import MDMPage from "../home/src/MasterDataManagement";
import WarehousePage from "../home/src/WarehouseManagement";
import PowerBIPage from "../home/src/PowerBI";
import DCataloguePage from "../home/src/DCatalogue";
import FuelManagementPage from "../home/src/FuelManagement";
import DigitalLinkPage from "../home/src/DigitalLink";
import IndustryVerticlesPage from "../home/src/IndustryVerticles";
import AboutPage from "../home/src/About";
import ContactPage from "../home/src/Contact";
import New from "../home/src/New";
import WareHouseView from "../label_print/src/view/WarehouseLocation";

const AppContent = () => {
  const { handleLogin, handleLogout, getUserName, getInitials } = UserUtils;
  const { handleToastOpen } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isProduct = pathName.includes('/warehouse/spare-part-info') || pathName.includes('/warehouse/whs-location-info')|| pathName.includes('/warehouse/asset-info') || pathName.includes('/warehouse/registration-asset');
  const isHeader = !!pathName && pathName !== "/" && pathName !== "/login";
  const localData = localStorage.getItem("permissionData");

  const handleStorageChange = () => {
    setUserName(getUserName());
    setUserInitials(getInitials());
  };
  window.addEventListener("cStorage", handleStorageChange);

  const services = [
    {
      key: "tool_dcat",
      title: "D-Catalogue",
      route: "/d-catalogue/shopping-basket",
      asset: dcatimg,
    },
    {
      key: "tool_warehouse",
      title: "Warehouse Management",
      route: "/warehouse/label-printing",
      asset: warehouseimg,
    },
    {
      key: "tool_gln",
      title: "Global Location Number",
      route: "https://global-location-data.conasservices.com/",
      asset: glnimg,
    },
    {
      key: "tool_fuel",
      title: "Fuel Management",
      route: "/",
      asset: fuelimg,
    },
  ];

  if (localData) {
    services.forEach((service) => {
      if (localData.includes(service.key)) {
        service.isEnabled = true;
      } else {
        service.isEnabled = false;
      }
    });
  }

  const appRouteMap = {
    "/": {
      element: <HomePage />,
    },
    "/login": {
      element: (
        <LoginView
          cLogo={ConasLogo}
          cBgImg={BgImg}
          handleToastOpen={() => handleToastOpen}
        />
      ),
    },
    "/services": {
      title: "Services",
      element: <Services services={services} linkComponent={Link} />,
    },
    "/ERP-Implementation": {
      // title: "ERP-Implementation",
      element: <ERPImplementationPage />,
    },
    "/MDM": {
      // title: "Master Data Management",
      element: <MDMPage />,
    },
    "/Warehouse": {
      // title: "Warehouse Management",
      element: <WarehousePage />,
    },
    "/PowerBI": {
      // title: "PowerBI Dashboards",
      element: <PowerBIPage />,
    },
    "/DCatalogue": {
      // title: "DCatalogue",
      element: <DCataloguePage />,
    },
    "/FuelManagement": {
      // title: "FuelManagement",
      element: <FuelManagementPage />,
    },
    "/DigitalLink": {
      // title: "DigitalLink",
      element: <DigitalLinkPage />,
    },
    "/IndustryVerticles": {
      // title: "IndustryVerticles",
      element: <IndustryVerticlesPage />,
    },
    "/About": {
      // title: "AboutPage",
      element: <AboutPage />,
    },
    "/Contact": {
      // title: "ContactPage",
      element: <ContactPage />,
    },
    "/New": {
      // title: "New",
      element: <New />,
    },
    "/d-catalogue/historical-job": {
      title: "Historical Job",
      element: <HistoricalJob />,
      isDrawer: true,
    },
    "/d-catalogue/historical-job-detail": {
      title: "Historical Job Detail",
      element: <HistoricalJobDetails />,
      isDrawer: false,
    },
    "/d-catalogue/shopping-basket": {
      title: "Shopping Basket",
      element: <ShoppingBasket />,
      isDrawer: true,
    },
    // "/warehouse/label-printing": {
    //   title: "Label Printing",
    //   element: <LabelPrinting />,
    //   isDrawer: true,
    // },
    // "/warehouse/label-upload": {
    //   title: "Upload Label Data",
    //   element: <UploadLabel />,
    //   isDrawer: true,
    // },
    "/warehouse/label-printing": {
      title: "Label Printing",
      element: <PrintingView />,
      isDrawer: true,
    },
    "/warehouse/label-upload": {
      title: "Upload Label Data",
      element: <UploadLabelView />,
      isDrawer: true,
    },
    "/warehouse/image-upload": {
      title: "Material Images Upload",
      element: <ImageUploadView />,
      isDrawer: true,
    },
    "/warehouse/registration-asset": {
      title: "Registration Data",
      element: <RegistrationDataView />,
      isDrawer: false,
    },
    "/warehouse/spare-part-info": {
      title: "Spare Part Info",
      element: <SparePartInfoView />,
      isDrawer: false,
    },
    "/warehouse/whs-location-info": {
      title: "WHS Location Info",
      element: <WareHouseView />,
      isDrawer: false,
    },
    "/warehouse/asset-info": {
      title: "Asset Info",
      element: <FuelAssetInfoView />,
      isDrawer: false,
    },
    "/warehouse/sap-bin-location": {
      title: "Bin Location Details",
      element: <BinLocationView />,
      isDrawer: true,
    },
  };
  const drawerList = ["/warehouse", "/d-catalogue"];
  const [pageTitle, setPageTitle] = useState("");

  const [userName, setUserName] = useState(getUserName());
  const [userInitials, setUserInitials] = useState(getInitials());

  useEffect(() => {
    const pathname = location.pathname;
    let title = "";
    if (appRouteMap[pathname]) {
      title = appRouteMap[pathname].title;
    } else {
      title = "";
    }
    setPageTitle(title);
  }, [location.pathname]);

  return (
    <>
      {isHeader && (
        <Header
          cLogo={ConasLogo}
          cLogoSmall={ConasLogoSmall}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          cUserName={userName}
          cUserInitials={userInitials}
          pageTitle={pageTitle}
          appRouteMap={appRouteMap}
          drawerList={drawerList}
          pathName={pathName}
          navigate={navigate}
          linkComponent={Link}
        />
      )}
      <CToast />
      <Routing appRouteMap={appRouteMap} />
      {!isProduct && (<Footer cLogo={ConasLogo} />)}
    </>
  );
};

export default AppContent;
