import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Button,
  Dialog,
  Toolbar,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import useSparePartInfoViewModel from "../viewmodel/SparePartInfoVM";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CButton from "../../../basecomp/components/CButton";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import CCarousel from "../../../basecomp/components/CCarousel";

const OrderBox = styled("div")({
  textAlign: "start",
  border: "1px solid #3CB043",
  width: "100%",
  margin: "2rem 0em",
  borderRadius: "1.2em 1.2em 1em 1em",
});

const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: ".5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
  fontSize: "1.2em",
});

const OrderBoxBtn = styled("div")({
  padding: "2em 3em",
  display: "flex",
  justifyContent: "center",
});

function SparePartInfoView() {
  const {
    materialDetails,
    handleOpenModal,
    modalOpen,
    setModalOpen,
    error,
    handleChange,
    quantity,
    addToCart,
  } = useSparePartInfoViewModel();

  const [openCarousel, setOpenCarousel] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const infoHeadData1 = ["Storage Location / Bin", "OHQ", "UOM"];
  const infoHeadData2 = ["Global MPN", "Local MPN"];
  const [aData1, setAData1] = useState([]);
  const [infoRowData, setInfoRowData] = useState([]);
  const [infoRowData1, setInfoRowData1] = useState([]);
  const [mpn, setMpn] = useState([]);
  const [localMpn, setLocalMpn] = useState([]);

  useEffect(() => {
    if (Array.isArray(materialDetails) && materialDetails.length > 0) {
      const materialDet = materialDetails[0];
      setMpn(materialDet.mpn_links);
      setLocalMpn(materialDet.local_mpn);
      setAssetData([
        [
          {
            name: "Plant ID",
            value:
              materialDet.plant_id === undefined ? " " : materialDet.plant_id,
          },
        ],
        [
          {
            name: "Asset ID",
            value:
              materialDet.asset_id === undefined ||
              materialDet.asset_id === null
                ? "\u00A0"
                : materialDet.asset_id,
          },
        ],
        [
          {
            name: "Local No.",
            value:
              materialDet.material_no === undefined ||
              materialDet.material_no === null
                ? "\u00A0"
                : materialDet.material_no,
          },
          {
            name: "Local Desc.",
            value:
              materialDet.material_desc === undefined ||
              materialDet.material_desc === null
                ? "\u00A0"
                : materialDet.material_desc,
          },
        ],
        [
          {
            name: "Global No.",
            value:
              materialDet.gbl_material_number === undefined ||
              materialDet.gbl_material_number === null
                ? "\u00A0"
                : materialDet.gbl_material_number,
          },
          {
            name: "Global Desc.",
            value:
              materialDet.gbl_material_desc === undefined ||
              materialDet.gbl_material_desc === null
                ? "\u00A0"
                : materialDet.gbl_material_desc,
          },
        ],
      ]);
      setInfoRowData([
        { value: `name`, type: "T" },
        { value: `value`, type: "T" },
      ]);

      setAData1([
        {
          storage_loc:
            (materialDet.storage_loc ? materialDet.storage_loc : "") +
            (materialDet.bin_location
              ? `${" / " + materialDet.bin_location}`
              : ""),
          ohqty: materialDet.ohqty,
          b_un: materialDet.b_un,
        },
      ]);
      setInfoRowData1([
        { value: `storage_loc`, type: "T" },
        { value: `ohqty`, type: "T" },
        { value: `b_un`, type: "T" },
      ]);
    }
  }, [materialDetails]);
  // ui changes
  return (
    <>
      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={10}
        xl={10}
        sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
      >
        <Card sx={{ padding: "0.5em" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {materialDetails && (
              <OrderBox>
                <OrderBoxText>General Info</OrderBoxText>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {assetData?.map((actualArr, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {infoRowData.map((rowData, cellIndex) => (
                            <TableCell
                              component="th"
                              scope="row"
                              key={cellIndex}
                              sx={{
                                padding: "0em 0.2em 0em 2em",
                                margin: "0em",
                                border: "none",
                                fontWeight: "bold",
                                width: cellIndex === 0 ? "40%" : "60%",
                              }}
                            >
                              {actualArr?.map((actualRow, rowIndex) => (
                                <TableRow key={rowIndex}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      textAlign: "left",
                                      padding: "0.8em 0em 0.8em 0em",
                                      verticalAlign: "middle",
                                      fontSize: "0.9em",
                                      border: "none",
                                    }}
                                  >
                                    {rowData.type === "T" &&
                                      actualRow[rowData.value]}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderBox>
            )}
          </Grid>

          {materialDetails && (
            <>
              <OrderBox sx={{ marginTop: "1em" }}>
                <OrderBoxText>Manufacturer Info</OrderBoxText>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {infoHeadData2?.map((headData, headIndex) => (
                          <TableCell
                            key={headIndex}
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              width: headIndex === 0 ? "50%" : "50%",
                            }}
                          >
                            {headData}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {mpn.map((link, index) => {
                        const mpnSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(
                          (link.mpn_name ? link.mpn_name : "") +
                            " " +
                            (link.mpn_number ? link.mpn_number : "")
                        )}`;
                        const mpnUrl = link?.mpn_url?.includes("***")
                          ? link.mpn_url.replace(
                              "***",
                              link.mpn_number ? link.mpn_number : ""
                            )
                          : link.mpn_url;

                        return (
                          <TableRow key={index}>
                            {/* Global Manufacturer Info */}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                textAlign: "center",
                                padding: "0.8em",
                                fontSize: "0.9em",
                                border: "none", // Remove border
                              }}
                            >
                              <a
                                href={link.mpn_url ? mpnUrl : mpnSearchUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#1976d2",
                                  textDecoration: "underline",
                                  display: "block",
                                }}
                              >
                                {link.mpn_name ? link.mpn_name + " - " : ""}{" "}
                                {link.mpn_number ? link.mpn_number : ""}
                              </a>
                            </TableCell>

                            {/* Local Manufacturer Info */}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                textAlign: "center",
                                padding: "0.8em",
                                fontSize: "0.9em",
                                border: "none", // Remove border
                              }}
                            >
                              {localMpn[index] ? (
                                <a
                                  href={
                                    localMpn[index]?.mpn_url?.includes("***")
                                      ? localMpn[index].mpn_url.replace(
                                          "***",
                                          localMpn[index].mpn_number
                                            ? localMpn[index].mpn_number
                                            : ""
                                        )
                                      : `https://www.google.com/search?q=${encodeURIComponent(
                                          (localMpn[index].mpn_name
                                            ? localMpn[index].mpn_name
                                            : "") +
                                            " " +
                                            (localMpn[index].mpn_number
                                              ? localMpn[index].mpn_number
                                              : "")
                                        )}`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#1976d2",
                                    textDecoration: "underline",
                                    display: "block",
                                  }}
                                >
                                  {localMpn[index].mpn_name
                                    ? localMpn[index].mpn_name + " - "
                                    : ""}
                                  {localMpn[index].mpn_number
                                    ? localMpn[index].mpn_number
                                    : ""}
                                </a>
                              ) : (
                                <>&nbsp;</> // Empty space for rows with no localMpn
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {/* Extra rows for Local MPN if localMpn has more items than mpn */}
                      {localMpn?.length > mpn.length &&
                        localMpn.slice(mpn.length).map((link, index) => (
                          <TableRow key={mpn.length + index}>
                            {/* Empty Global Manufacturer Cell */}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                textAlign: "center",
                                padding: "0.8em",
                                fontSize: "0.9em",
                                border: "none", // Remove border
                              }}
                            >
                              &nbsp;
                            </TableCell>

                            {/* Local Manufacturer Info */}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                textAlign: "center",
                                padding: "0.8em",
                                fontSize: "0.9em",
                                border: "none", // Remove border
                              }}
                            >
                              <a
                                href={
                                  link?.mpn_url?.includes("***")
                                    ? link.mpn_url.replace(
                                        "***",
                                        link.mpn_number ? link.mpn_number : ""
                                      )
                                    : `https://www.google.com/search?q=${encodeURIComponent(
                                        (link.mpn_name ? link.mpn_name : "") +
                                          " " +
                                          (link.mpn_number
                                            ? link.mpn_number
                                            : "")
                                      )}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#1976d2",
                                  textDecoration: "underline",
                                  display: "block",
                                }}
                              >
                                {link.mpn_name ? link.mpn_name + " - " : ""}
                                {link.mpn_number ? link.mpn_number : ""}
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderBox>
            </>
          )}

          {materialDetails && (
            <>
              <OrderBox sx={{ marginTop: "3em" }}>
                <OrderBoxText>Inventory Location Details</OrderBoxText>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {infoHeadData1?.map((headData, headIndex) => (
                          <TableCell
                            key={headIndex}
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              width: headIndex === 0 ? "70%" : "15%",
                            }}
                          >
                            {headData}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {aData1?.map((actualRow, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {infoRowData1?.map((rowData, cellIndex) => (
                            <TableCell
                              component="th"
                              scope="row"
                              key={cellIndex}
                              sx={{
                                textAlign: "center",
                                border: "none",
                                width: cellIndex === 0 ? "70%" : "15%",
                              }}
                            >
                              {rowData.type === "T" && actualRow[rowData.value]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </OrderBox>
            </>
          )}
          <Grid item sx={{ marginTop: "3em" }}>
            {materialDetails && (
              <OrderBox>
                <OrderBoxText>Links</OrderBoxText>
                <OrderBoxBtn>
                  <CButtonGroup>
                    {materialDetails?.[0]?.image_link?.length > 0 && (
                      <CButton
                        cId="gsone_sparepartinfo_image_btn_link"
                        cTooltipText="Image"
                        cSrc={<CameraAltIcon />}
                        cOnClick={() => setOpenCarousel(true)}
                        cIsMain={true}
                      />
                    )}

                    <Link
                      to={
                        "/d-catalogue/shopping-basket?lcl_mat_no=" +
                        materialDetails[0].material_no
                      }
                    >
                      <CButton
                        cId="gsone_sparepartinfo_matno_btn_search"
                        cTooltipText="Material Search"
                        cSrc={<SearchIcon />}
                        cIsMain={true}
                      />
                    </Link>
                    <CButton
                      cId="gsone_sparepartinfo_add_btn_cart"
                      cTooltipText="Add to Cart"
                      cSrc={<ShoppingCartOutlinedIcon />}
                      cOnClick={() => handleOpenModal(materialDetails[0].id)}
                      cIsMain={true}
                    />
                  </CButtonGroup>
                </OrderBoxBtn>
              </OrderBox>
            )}
          </Grid>
        </Card>
      </Grid>
      {openCarousel && (
        <CCarousel
          cOpen={openCarousel}
          cImages={materialDetails[0].image_link}
          cCloseClicked={() => setOpenCarousel(false)}
        />
      )}

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          style: {
            marginBottom: "25em",
            maxWidth: "23em",
            width: "100%",
          },
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#3CB043",
            alignItems: "center",
          }}
        >
          <Toolbar>
            <ShoppingCartOutlinedIcon />
          </Toolbar>
        </AppBar>

        <DialogContent>
          <div className="modal-body">
            <Typography
              style={{
                color: "#1976d2",
                marginBottom: "1em",
              }}
            >
              QTY
            </Typography>
            <TextField
              id="standard-basic"
              fullWidth
              value={quantity}
              onChange={handleChange}
              variant="standard"
            />
            {error && <span>{error}</span>}
          </div>
        </DialogContent>

        <DialogActions>
          <Button style={{ color: "#1976d2" }} onClick={addToCart}>
            OK
          </Button>
          <Button
            onClick={() => setModalOpen(false)}
            style={{ color: "#1976d2" }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SparePartInfoView;
