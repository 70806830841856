import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkAssetType,
  getDCatTerminalDetails,
  getWorkOrderDetails,
} from "../model/GSMainPage";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

function useFuelAssetViewModel() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const asset_id =
    decodeURIComponent(searchParams.get("asset_id")) !== "null"
      ? decodeURIComponent(searchParams.get("asset_id"))
      : "";
  const { handleToastOpen } = useToast();
  const [equipmentNo, setEquipmentNo] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentAvailability, setEquipmentAvailability] = useState("");
  const [plant, setPlant] = useState("");
  const [konecranesId, setKonecranesId] = useState("");
  const [catalogueId, setCatalogueId] = useState("");
  const [workOrderDetails, setWorkOrderDetails] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const externalScriptRef = useRef();

  const notificationInfo = [
    "Functional Location",
    "Notification Number",
    "Description",
    "Malfunction Start Date Time",
    "Notification Type",
  ];

  const workDetailsInfo = [
    "Functional Location",
    "Order Number",
    "Description",
    "Downtime Details",
    "Actual Start Date Time",
    "Actual Finish Date Time",
  ];

  useEffect(() => {
    setLoading(true);
    checkAssetType(asset_id)
      .then((response) => {
        console.log(
          response.data
        );
        
        if (response.data.id) {
          setPlant(response.data.terminal_id);
          setEquipmentNo(response.data.fuel_asset_id);
          setEquipmentName(response.data.fuel_asset_name);
        } else if(response.data.status===204) {
          setEquipmentNo("");
          setEquipmentName("");
          navigate(`/warehouse/registration-asset?asset_id=${asset_id}`);
        }
      })
      .catch((error) => {
        setError(error);
        setEquipmentNo("");
        setEquipmentName("");
        setPlant("");
        navigate(`/warehouse/registration-asset?asset_id=${asset_id}`);
      })
      .finally(() => setLoading(false));
  }, [asset_id]);


  // Separate useEffect to set equipmentAvailability based on workOrderDetails
  useEffect(() => {
    if (workOrderDetails?.length > 0 ) {
      console.log(
        workOrderDetails[0]?.equipment_availability,
        "workOrderDetails[0]?.equipment_availability"
      );

      if (workOrderDetails[0]?.equipment_availability == null) {
        setEquipmentAvailability("Obsolete");
        return;
      }
      setEquipmentAvailability(workOrderDetails[0]?.equipment_availability);
    } else if (
      workOrderDetails?.length === 0 
    ) {
      setEquipmentAvailability("Available");
    }
  }, [workOrderDetails]);

  useEffect(() => {
    setLoading(true);
    if (equipmentNo) {
      getWorkOrderDetails(equipmentNo)
        .then((response) => {
          setWorkOrderDetails(response);
        })
        .catch((error) => {
          setError(error);
          setWorkOrderDetails([]);
        })
        .finally(() => setLoading(false));
    }
  }, [equipmentNo]);

  useEffect(() => {
    setLoading(true);
    if (equipmentNo) {
      getDCatTerminalDetails(equipmentNo)
        .then((response) => {
          setKonecranesId(response[0].konecranes_id);
        })
        .catch((error) => {
          setError(error);
          setKonecranesId("");
        })
        .finally(() => setLoading(false));
    }
  }, [equipmentNo]);

  const handleDCatTerminalDetails = async () => {
    try {
      const response = await getDCatTerminalDetails(equipmentNo);
      console.log(response, typeof response);

      // Check if response is undefined or not an array, or if the array is empty
      if (!response || !Array.isArray(response) || response.length === 0) {
        handleToastOpen("error", "No Catalogue found");
        return; // Exit the function to prevent further execution
      }

      // Check if catalogue_id exists in the first item of the array
      if (!response[0].catalogue_id) {
        handleToastOpen("error", "No Catalogue found");
        return;
      }

      // If catalogue_id exists, set it
      setCatalogueId(response[0].catalogue_id);
      console.log("Catalogue ID set to: ", response[0].catalogue_id);
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error fetching catalogue details:", error);
      handleToastOpen("error", "No Catalogue found");
    }
  };

  //Third party Tool
  const openEquipmentCatalogue = async () => {
    const usrtoken = localStorage.getItem("token");
    // Initialize externalScriptRef here
    externalScriptRef.current = window;
    // Wait for the 'layerLoaded' event
    window.addEventListener("layerLoaded", () => {
      setTimeout(function () {
        // Call setLayerCookie with the desired parameters
        externalScriptRef.current.setLayerCookie("user_token", usrtoken);
      }, 1000);
    });
    externalScriptRef.current.openLayerByReleaseId(catalogueId, true);
  };

  useEffect(() => {
    if (catalogueId) {
      openEquipmentCatalogue();

      // Find the button element
      const closeButton = document.getElementById("close_tab_close_button");

      if (closeButton) {
        // Add click event listener
        closeButton.addEventListener("click", async () => {
          await new Promise((resolve) => setTimeout(resolve, 100)); // Optional delay for tasks to complete
          setCatalogueId(""); // Reset catalogueId after closing the catalogue
        });

        // Cleanup the event listener when component unmounts or catalogueId changes
        return () => closeButton.removeEventListener("click", () => {});
      }
    }
  }, [catalogueId]);

  const handleExternalLinkClick = () => {
    window.open(
      `https://your.konecranes.com/#/asset-fleet/customers/KC_cod_1-VRKDNIH/locations/136146/assets/${konecranesId}/ports-alerts`,
      "_blank"
    );
  };

  const spBasketRedirect = () => {
    window.open(`/d-catalogue/shopping-basket`, '_blank');
  };
  
//Button changes
  return {
    error,
    notificationInfo,
    workDetailsInfo,
    workOrderDetails,
    asset_id,
    equipmentName,
    equipmentNo,
    plant,
    equipmentAvailability,
    handleDCatTerminalDetails,
    handleExternalLinkClick,
    konecranesId,
    loading,
    spBasketRedirect
  };
}

export default useFuelAssetViewModel;
