import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import useRegistrationDataViewModel from "../viewmodel/RegistrationVM";
import useGsOneMainPageViewModel from "../viewmodel/GSMainPageVM";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const inputStyle = {
  width: "100%",
  marginBottom: "1em",
};

function RegistrationDataView() {
  const {
    plantOptions,
    material,
    materialCheckResult,
    option1,
    option2,
    showAdditionalFields,
    additionalFieldName,
    handleSubmit,
    handleOption1Change,
    handleOption2Change,
    setMaterial,
    handleImageUploadObj,
    handleImageUploadAsset,
  } = useRegistrationDataViewModel();

  const { asset_id } = useGsOneMainPageViewModel();

  return (
    <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
      <Card sx={{ padding: "2em" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <TextField
              id="asset-id"
              label="Asset ID"
              value={asset_id}
              variant="outlined"
              disabled
              style={inputStyle}
            />
            <FormControl variant="outlined" style={inputStyle}>
              <InputLabel>Plant</InputLabel>
              <Select value={option1} onChange={handleOption1Change} label="Plant">
                {plantOptions.map((plant) => (
                  <MenuItem key={plant.id} value={plant.id}>
                    {plant.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a plant</FormHelperText>
            </FormControl>
            <FormControl variant="outlined" style={inputStyle}>
              <InputLabel>Asset Type</InputLabel>
              <Select
                value={option2}
                onChange={handleOption2Change}
                label="Asset Type"
              >
                <MenuItem value="option1">Asset</MenuItem>
                <MenuItem value="option2">Spare Part</MenuItem>
              </Select>
              <FormHelperText>Select an asset type</FormHelperText>
            </FormControl>
            {showAdditionalFields && option2 === "option2" && (
              <>
                <TextField
                  id="material-no"
                  label={additionalFieldName}
                  variant="outlined"
                  value={material}
                  onChange={(event) => setMaterial(event.target.value)}
                  style={inputStyle}
                />
                <p
                  style={{
                    color: materialCheckResult === null ? "red" : "green",
                    marginBottom: "1em",
                  }}
                >
                  {materialCheckResult === null
                    ? "Material Is not Present"
                    : materialCheckResult.material_desc}
                </p>
              </>
            )}
            {showAdditionalFields && option2 === "option1" && (
              <>
                <TextField
                  id="fuel-asset-id"
                  label="Equipment ID"
                  variant="outlined"
                  style={inputStyle}
                />
                <TextField
                  id="fuel-asset-name"
                  label="Equipment Type"
                  variant="outlined"
                  style={inputStyle}
                />
              </>
            )}

            <TextField
              id="object-photo"
              label="Upload Object Photo"
              variant="outlined"
              type="file"
              required
              InputLabelProps={{ shrink: true }}
              onChange={handleImageUploadObj}
              style={inputStyle}
            />

            <TextField
              id="id-plate-photo"
              label="Upload ID Plate Photo"
              variant="outlined"
              type="file"
              required
              InputLabelProps={{ shrink: true }}
              onChange={handleImageUploadAsset}
              style={inputStyle}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={inputStyle}
              disabled={materialCheckResult === null}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Card>
    </Grid>
  );
}

export default RegistrationDataView;
