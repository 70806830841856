import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LinkIcon from "@mui/icons-material/Link";
import {
  Grid,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
} from "@mui/material";
import CButton from "../../../basecomp/components/CButton";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import FuelAssetVM from "../viewmodel/FuelAssetVM";
import CBackDrop from "../../../basecomp/components/CBackDrop";

const OrderBox = styled("div")({
  textAlign: "start",
  border: "1px solid #3CB043",
  width: "100%",
  margin: "2rem 0em",
  marginBottom: "3em",
  borderRadius: "1.2em 1.2em 1em 1em",
});

const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: ".5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
  fontSize: "1.2em",
});

const OrderBoxBtn = styled("div")({
  padding: "2em 3em",
  display: "flex",
  justifyContent: "center",
});

const commonFontSize = ".9em";

function FuelAssetInfoView() {
  const {
    workDetailsInfo,
    workOrderDetails = [], // Default to an empty array
    asset_id,
    equipmentName,
    equipmentNo,
    plant,
    equipmentAvailability,
    handleDCatTerminalDetails,
    handleExternalLinkClick,
    konecranesId,
    loading,
    spBasketRedirect,
  } = FuelAssetVM();

  // Set asset data using state and render in a table format
  const assetData = [
    { label: "Asset ID", value: asset_id },
    { label: "Plant ID", value: plant },
    { label: "Equipment Type", value: equipmentName },
    { label: "Equipment ID", value: equipmentNo },
    { label: "Status", value: equipmentAvailability },
  ];

  sessionStorage.setItem(
    "logingsoneurl",
    `/warehouse/asset-info?asset_id=${asset_id}`
  );

  const filteredWorkOrderDetails = Array.isArray(workOrderDetails)
  ? workOrderDetails.map((workOrder) => {
      const { equipment_availability, ...rest } = workOrder || {};
      return rest;
    })
  : [];

console.log(filteredWorkOrderDetails);

  return (
    <>
      <Box sx={{ margin: "6em 1em 0.5em 1em" }}>
        <CBackDrop cOpen={loading} />
        <OrderBox>
          <OrderBoxText>General Info</OrderBoxText>
          <Box
            sx={{ padding: { xs: "0.5em", sm: "2em" }, height: "fit-content" }}
          >
            {assetData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: ".8em",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    minWidth: "170px",
                    marginRight: { xs: "0.5em", sm: "1em" },
                    wordWrap: "break-word",
                    fontSize: commonFontSize,
                  }}
                >
                  {item.label}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ wordWrap: "break-word", fontSize: commonFontSize }}
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </OrderBox>

        {/* Work Order Details Section */}
        <OrderBoxText>Open Work Orders</OrderBoxText>
        <Box
          sx={{
            border: "1px solid #3CB043", // Green border around the table
            borderRadius: "0 0 1em 1em", // Apply border radius to the bottom
            overflowX: "auto", // Enables horizontal scrolling if content overflows
            whiteSpace: "nowrap", // Prevents table content from wrapping
            marginBottom: "3em", // Adds space below the table
            scrollbarWidth: "none", // Hides scrollbar in Firefox
            "&::-webkit-scrollbar": {
              display: "none", // Hides scrollbar in Chrome, Safari, and Edge
            },
          }}
        >
          <Table sx={{ minWidth: "650px" }}>
            <TableHead>
              <TableRow>
                {workDetailsInfo.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontWeight: "bold",
                      padding: "6px 24px",
                      fontSize: commonFontSize,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWorkOrderDetails?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map((colKey, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={{ padding: "8px 24px", fontSize: commonFontSize }}
                    >
                      {row[colKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {filteredWorkOrderDetails?.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={workDetailsInfo.length}
                    sx={{ textAlign: "center" }}
                  >
                    No data present
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Grid item sx={{ margin: "3em 0" }}>
          <OrderBox>
            <OrderBoxText>Links</OrderBoxText>
            <OrderBoxBtn>
              <CButtonGroup>
                <CButton
                  cId="d-cat"
                  cTooltipText="Click here to open the Catalogue"
                  cSrc={<ArchitectureIcon />}
                  cOnClick={handleDCatTerminalDetails}
                  cIsMain={true}
                />
                <CButton
                  cId="d-cat"
                  cTooltipText="Click here to open the Shopping Basket"
                  cSrc={<ShoppingCartIcon />}
                  cOnClick={spBasketRedirect}
                  cIsMain={true}
                />
                <CButton
                  cId="link"
                  cTooltipText="Click here to open the Konecranes view"
                  cSrc={<LinkIcon />}
                  cDisabled={!konecranesId}
                  cOnClick={handleExternalLinkClick}
                  cIsMain={true}
                />
              </CButtonGroup>
            </OrderBoxBtn>
          </OrderBox>
        </Grid>
      </Box>
    </>
  );
}

export default FuelAssetInfoView;
